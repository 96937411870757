import React, {useEffect, useState} from 'react';
import {CodeSendStatuses, GroupDetailTableDataT, CodeStatusTypes} from '../../../types/codes';
import {Modal} from 'antd';
import {ModalDescr} from '../../Dashboard/styles';
import {CenteredLoader} from '../../../ui-kit/Loader';
import {
  ViewTable,
  SearchResultWrapper,
  ResultLabel,
  ClearButton,
  TableViewSelect,
  AboveTableLine,
  ClearWrapper,
} from './styles';
import {TableWrapper} from '../../../ui-kit/Table';
import {
  TableViews,
  useCodesView,
  useEditingCodes,
  useGuestChangeCodes,
  usePromoCodesActionsT,
  useShareCode,
} from '../../../hooks/codes';
import {updateTargetT, useSetEmailsT} from '../../../hooks/emails';
import {getTableColumns} from './TableColumns';
import {useEmailPreview} from '../../../containers/CodeGroup/EmailPreview';

export enum ModalLvl {
  closed = 'closed',
  confirm = 'confirm',
  success = 'success',
  error = 'error',
}

type GroupDetailTableT = {
  groupId?: string | number;
  promoCodes?: GroupDetailTableDataT[];
  onUpdate?: usePromoCodesActionsT['onUpdate'];
  onDelete?: usePromoCodesActionsT['onDelete'];
  onDeletePromoCode?: usePromoCodesActionsT['onDelete'];
  listLoading?: boolean;
  onSendCode: (id?: string) => Promise<boolean>;
  onSendGuest: (ids?: string[]) => Promise<boolean>;
  emailsData: useSetEmailsT;
  updateTarget: updateTargetT;
  searchPromoCodes: (v: string) => void;
  fullRefetch?: () => void;
  setTableView: (view: TableViews) => void;
  tableView: TableViews;
  filter: CodeStatusTypes;
  setFilter: (v: CodeStatusTypes) => void;
  totalCodes?: number;
  usedCodes?: number;
};
const tableViewOptions = [
  {label: 'Distribution View', value: TableViews.guests},
  {label: 'Codes List View', value: TableViews.codes},
];
export type emailState = Record<any, emailStateVal>;
export type emailStateVal = {key: string; email?: string; name?: string};

export const GroupDetailTable: React.FC<GroupDetailTableT> = ({
  promoCodes,
  onUpdate,
  onDelete,
  listLoading,
  onSendCode,
  onSendGuest,
  emailsData,
  updateTarget,
  searchPromoCodes,
  fullRefetch,
  setTableView,
  tableView,
  onDeletePromoCode,
  filter,
  setFilter,
  totalCodes,
  usedCodes,
  groupId,
}) => {
  const {emails: guestState, handleSetEmail, handleSetName, reset} = emailsData;
  const [focusedId, setFocusedId] = useState<string | undefined>('');
  const [editingId, setEditingId] = useState<string | undefined>('');
  const [delModalLvl, setDelModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [sendModalLvl, setSendModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchRes, setIsSearchRes] = useState<boolean>(false);
  const setDelPopup = (lvl: ModalLvl) => setDelModalLvl(lvl);
  const setSendPopup = (lvl: ModalLvl) => setSendModalLvl(lvl);
  const unused = promoCodes?.[0]?.code?.unused;
  const codesState = useGuestChangeCodes(promoCodes);
  const [loading, setLoading] = useState<boolean>(false);
  const tableViewChange = (val: any) => setTableView(val);
  const setFocusId = (id?: string) => setFocusedId(id);
  const setEditing = (id?: string) => setEditingId(id);
  const handleSend = (id?: string) => {
    setFocusedId(id);
    setSendPopup(ModalLvl.confirm);
  };
  const {shareLoading, toggleShare} = useShareCode(groupId, fullRefetch);
  const {promoState, onUpdatePromo, changePromoState} = useCodesView(
    tableView,
    promoCodes,
    editingId,
    onUpdate,
    setEditing,
  );
  const {loadEmailPreview} = useEmailPreview(groupId);
  const editingCodeStatus = promoCodes?.find((cod) => cod.id === editingId)?.actions.codeStatus;
  const codeStatus = promoCodes?.find((cod) => cod.id === focusedId)?.actions.codeStatus;
  const sendText = codeStatus === CodeSendStatuses.sent || codeStatus === CodeSendStatuses.queued ? 'resend' : 'send';
  const send = async (id?: string) => {
    if (!id) return false;
    setLoading(true);
    try {
      if (tableView === TableViews.guests) {
        const res = await onSendGuest?.([id]);
        if (res) fullRefetch?.();
      }
      if (tableView === TableViews.codes) {
        const res = await onSendCode?.(id);
        if (res) fullRefetch?.();
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    setFocusedId('');
  };
  const onEditSuccess = async (id?: string) => {
    const sent = promoCodes
      ?.find((el) => el?.id === id)
      ?.code?.used?.some((el) => el?.status !== CodeSendStatuses.notsent);
    if (sent) {
      handleSend(id);
    } else {
      setFocusId('');
    }
    reset();
    setEditingId('');
  };
  const updateTargets = updateTarget(codesState.codes, false, unused, onEditSuccess);
  const {eCodeState, handleEdit, onSave} = useEditingCodes(
    promoCodes?.find((el) => el?.id === editingId),
    onUpdate,
    editingId,
    onEditSuccess,
  );
  useEffect(() => {
    if (tableView === TableViews.guests) {
      if (delModalLvl === ModalLvl.confirm) {
        Modal.confirm({
          title: `Do you want to delete this guest?`,
          content: (
            <ModalDescr>
              Once confirm, the guest will be removed from the Group, and you won’t be able to make any further edits.
            </ModalDescr>
          ),
          okText: 'Yes, proceed to delete',
          onOk: async () => {
            const res = await onDelete?.(focusedId);
            res && setDelPopup(ModalLvl.success);
          },
          onCancel: () => setDelPopup(ModalLvl.closed),
        });
      }
    }
    if (tableView === TableViews.codes) {
      if (delModalLvl === ModalLvl.confirm) {
        Modal.confirm({
          title: `Do you want to delete this promocode?`,
          content: (
            <ModalDescr>
              Once confirm, the promo will be removed from the Group, and you won’t be able to make any further edits.
            </ModalDescr>
          ),
          okText: 'Yes, proceed to delete',
          onOk: async () => {
            const res = await onDeletePromoCode?.(focusedId);
            res && setDelPopup(ModalLvl.success);
          },
          onCancel: () => setDelPopup(ModalLvl.closed),
        });
      }
    }

    if (delModalLvl === ModalLvl.success) {
      const item = tableView === TableViews.codes ? 'promo' : 'guest';
      Modal.success({
        title: `The guest has been deleted!`,
        content: <ModalDescr>You will not see the {item} now.</ModalDescr>,
        okText: 'Done',
        onOk: () => {
          setDelPopup(ModalLvl.closed);
        },
      });
    }
  }, [delModalLvl]);

  useEffect(() => {
    const email = promoCodes?.find((el) => el?.id === focusedId)?.email?.email;
    if (sendModalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: `Are you ready to ${sendText} this code to ${email}?`,
        content: (
          <ModalDescr>
            Please make sure the email address is correct. With this action, your guest will receive an email with the
            ticket. This action cannot be undone.
          </ModalDescr>
        ),
        okText: 'Yes',
        onOk: async () => {
          await send(focusedId);
          setSendPopup(ModalLvl.closed);
        },
        onCancel: () => setSendPopup(ModalLvl.closed),
      });
    }
  }, [sendModalLvl, focusedId]);
  useEffect(() => {
    setEditing('');
  }, [tableView]);
  const columns = getTableColumns({
    tableView: tableView,
    searchValue: searchValue,
    setSearchValue: setSearchValue,
    setIsSearchRes,
    codesState,
    eCodeSetState: handleEdit,
    searchPromoCodes: searchPromoCodes,
    focusId: focusedId,
    onSendCode: handleSend,
    setFocusId: setFocusedId,
    loading: loading,
    emailstate: guestState,
    eCodeState: eCodeState,
    editingId: editingId,
    onUpdateCode: onSave,
    setEditingId: setEditing,
    updateTarget: updateTargets,
    setDelPopup: setDelPopup,
    setEmail: handleSetEmail,
    setName: handleSetName,
    editingCodeStatus: editingCodeStatus,
    promoState,
    changePromoState,
    onUpdatePromo,
    totalCodes,
    usedCodes,
    toggleShare,
    shareLoading,
    onEmailPreview: loadEmailPreview,
  });

  return (
    <>
      <AboveTableLine>
        <TableViewSelect value={tableView} options={tableViewOptions} onChange={tableViewChange} />
        <ClearWrapper>
          {filter !== CodeStatusTypes.all && <ClearFilter setFilter={setFilter} />}
          {isSearchRes && searchValue && (
            <SearchResult
              resQty={(promoCodes?.length || 1) - (tableView === TableViews.codes ? 0 : 1)}
              showResult={setIsSearchRes}
              searchPromoCodes={searchPromoCodes}
              value={searchValue}
              setValue={setSearchValue}
            />
          )}
        </ClearWrapper>
      </AboveTableLine>
      <TableWrapper>
        <ViewTable columns={columns} dataSource={promoCodes} showHeader={true} />
      </TableWrapper>
      {listLoading && <CenteredLoader />}
    </>
  );
};

export type SearchProps = {
  value: string;
  setValue: (v: string) => void;
  searchPromoCodes: (v: string) => void;
  showResult: (v: boolean) => void;
};

type SearchResultProps = SearchProps & {
  resQty?: number;
};

type ClearFilterProps = {
  setFilter: (v: CodeStatusTypes) => void;
};

const SearchResult: React.FC<SearchResultProps> = ({resQty, showResult, value, setValue, searchPromoCodes}) => {
  const onClearClick = () => {
    setValue('');
    searchPromoCodes('');
    showResult(false);
  };

  return (
    <SearchResultWrapper>
      <ResultLabel>{`${resQty} Results for "${value}"`}</ResultLabel>
      <ClearButton onClick={onClearClick}>Clear Search</ClearButton>
    </SearchResultWrapper>
  );
};

const ClearFilter: React.FC<ClearFilterProps> = ({setFilter}) => {
  const onClearClick = () => {
    setFilter(CodeStatusTypes.all);
  };
  return (
    <SearchResultWrapper>
      <ClearButton onClick={onClearClick}>Clear Filter</ClearButton>
    </SearchResultWrapper>
  );
};
