import React from 'react';
import styled from 'styled-components';
import {EmailPreviewT} from '../../../types/codes';

export const EmailPreview = ({emailBody, from, subject, to}: EmailPreviewT) => {
  return (
    <Wrapper>
      <Divider />
      <Row>
        <RowTitle>Subject:</RowTitle>
        <RowValue>{subject}</RowValue>
      </Row>
      <Row>
        <RowTitle>To:</RowTitle>
        <RowValue>{to}</RowValue>
      </Row>
      <Row>
        <RowTitle>From:</RowTitle>
        <RowValue>{from?.email || from?.name}</RowValue>
      </Row>
      <Divider />
      <Template dangerouslySetInnerHTML={{__html: emailBody || ''}} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Row = styled.div`
  display: flex;
  margin-bottom: 6px;
`;

const RowTitle = styled.div`
  width: 90px;
  font-weight: bold;
`;

const RowValue = styled.div``;

const Divider = styled.div`
  margin: 12px 0;
  height: 1px;
  background-color: #cccfd0;
`;

const Template = styled.div`
  max-height: 450px;
  overflow: scroll;
`;
