import React, {useState} from 'react';
import {GroupDetailTableColumnsTitles, GroupDetailTableFields, TableColumnT} from '../../../helpers/table';
import {codeState, codeStateVal, PromoStateT, TableViews} from '../../../hooks/codes';
import {ActionsItem, CodeItemProps, CodesItem, InputItem, QuantityItem, ActionsItemProps} from './TableItems';
import {SearchWrapper, SearchInput, EnterButton, Centered} from './styles';
import {SearchBtn} from '../../Dashboard/styles';
import {Dropdown} from 'antd';
import {CloseOutlined, SearchOutlined} from '@ant-design/icons';
import {ModalLvl, SearchProps} from './Table';
import {CodeSendStatuses, CodeViewFields, CodeViewStateChange} from '../../../types/codes';
import {CodeViewActionsItem, CodeViewInputItem, CodeViewPromoItem} from './CodeViewTableItems';

type getColumnsPropsT = CodeItemProps &
  ActionsItemProps & {
    tableView: TableViews;
    searchPromoCodes: (v: string) => void;
    setSearchValue: (v: string) => void;
    searchValue: string;
    setIsSearchRes: (v: boolean) => void;
    eCodeState?: codeState;
    eCodeSetState?: (val: codeStateVal) => void;
    isEditing?: boolean;
    updateTarget: (id?: string, eCodeState?: codeState, isAdd?: boolean) => Promise<boolean | undefined>;
    setDelPopup?: (lvl: ModalLvl) => void;
    editingCodeStatus?: CodeSendStatuses;
    promoState?: PromoStateT;
    changePromoState: CodeViewStateChange;
    onUpdatePromo?: (id?: string) => void;
    totalCodes?: number;
    usedCodes?: number;
    shareLoading?: string;
    onEmailPreview?: (id?: string) => Promise<void>;
  };

export const getTableColumns = (props: getColumnsPropsT): TableColumnT[] => {
  const {
    focusId,
    setEmail,
    emailstate,
    setFocusId,
    editingId,
    eCodeState,
    eCodeSetState,
    searchPromoCodes,
    setSearchValue,
    setIsSearchRes,
    searchValue,
    tableView,
    codesState,
    setDelPopup,
    onSendCode,
    loading,
    onUpdateCode,
    setEditingId,
    updateTarget,
    setName,
    editingCodeStatus,
    promoState,
    changePromoState,
    onUpdatePromo,
    usedCodes,
    totalCodes,
    toggleShare,
    shareLoading,
    onEmailPreview,
  } = props;
  if (tableView === TableViews.guests)
    return [
      {
        key: GroupDetailTableFields.guestName,
        title: 'Name',
        dataIndex: GroupDetailTableFields.guestName,
        render: (props: CodeItemProps) => (
          <InputItem
            {...props}
            focusId={focusId}
            setEmail={setName}
            emailstate={emailstate}
            setFocusId={setFocusId}
            field={'name'}
            editingId={editingId}
            eCodeState={eCodeState}
            eCodeSetState={eCodeSetState}
          />
        ),
      },
      {
        key: GroupDetailTableFields.email,
        title: 'Email',
        dataIndex: GroupDetailTableFields.email,
        render: (props: CodeItemProps) => (
          <InputItem
            {...props}
            focusId={focusId}
            setEmail={setEmail}
            emailstate={emailstate}
            setFocusId={setFocusId}
            field={'email'}
            editingId={editingId}
            eCodeState={eCodeState}
            eCodeSetState={eCodeSetState}
          />
        ),
      },
      {
        key: GroupDetailTableFields.quantity,
        title: (
          <div>
            <Centered>{GroupDetailTableColumnsTitles.quantity}</Centered>
            <Centered>
              <span>
                {usedCodes}/{totalCodes}
              </span>
            </Centered>
          </div>
        ),
        dataIndex: GroupDetailTableFields.code,
        render: (props: CodeItemProps) => (
          <QuantityItem {...props} editingId={editingId} codesState={codesState} codeStatus={editingCodeStatus} />
        ),
      },
      {
        key: GroupDetailTableFields.code,
        title: GroupDetailTableColumnsTitles.code,
        dataIndex: GroupDetailTableFields.code,
        render: (props: CodeItemProps) => <CodesItem {...props} editingId={editingId} codesState={codesState} />,
      },
      {
        key: GroupDetailTableFields.actions,
        title: (
          <>
            {/*{GroupDetailTableColumnsTitles.actions}*/}
            <Search
              searchPromoCodes={searchPromoCodes}
              showResult={setIsSearchRes}
              value={searchValue}
              setValue={setSearchValue}
            />
          </>
        ),
        dataIndex: GroupDetailTableFields.actions,
        render: (props: ActionsItemProps) => (
          <ActionsItem
            {...props}
            focusedId={focusId}
            onSendCode={onSendCode}
            setFocusId={setFocusId}
            loading={loading}
            emailstate={emailstate}
            eCodeState={eCodeState}
            editingId={editingId}
            onUpdateCode={onUpdateCode}
            setEditingId={setEditingId}
            updateTarget={updateTarget}
            codeCount={props?.id ? codesState.codes?.[props.id] : 0}
            setDelPopup={setDelPopup}
            onEmailPreview={onEmailPreview}
          />
        ),
      },
    ];

  return [
    {
      key: GroupDetailTableFields.code,
      title: GroupDetailTableColumnsTitles.code,
      dataIndex: GroupDetailTableFields.code,
      render: (props: CodeItemProps) => <CodeViewPromoItem {...props} />,
    },
    {
      key: GroupDetailTableFields.guestName,
      title: 'Name',
      dataIndex: GroupDetailTableFields.guestName,
      render: (props: CodeItemProps) => (
        <CodeViewInputItem
          {...props}
          field={CodeViewFields.name}
          editingId={editingId}
          state={props?.id ? promoState?.[props.id] : undefined}
          onChange={changePromoState}
        />
      ),
    },
    {
      key: GroupDetailTableFields.email,
      title: 'Email',
      dataIndex: GroupDetailTableFields.email,
      render: (props: CodeItemProps) => (
        <CodeViewInputItem
          {...props}
          field={CodeViewFields.email}
          editingId={editingId}
          state={props?.id ? promoState?.[props.id] : undefined}
          onChange={changePromoState}
        />
      ),
    },
    {
      key: GroupDetailTableFields.actions,
      title: (
        <>
          {/*{GroupDetailTableColumnsTitles.actions}*/}
          <Search
            searchPromoCodes={searchPromoCodes}
            showResult={setIsSearchRes}
            value={searchValue}
            setValue={setSearchValue}
          />
        </>
      ),
      dataIndex: GroupDetailTableFields.actions,
      render: (props: ActionsItemProps) => (
        <CodeViewActionsItem
          {...props}
          onSendCode={onSendCode}
          setFocusId={setFocusId}
          loading={loading}
          editingId={editingId}
          onUpdateCode={onUpdatePromo}
          setEditingId={setEditingId}
          setDelPopup={setDelPopup}
          state={promoState}
          toggleShare={toggleShare}
          shareLoading={shareLoading}
        />
      ),
    },
  ];
  //CODES
  //   return [
  //     {
  //       key: GroupDetailTableFields.guestName,
  //       title: 'Name',
  //       dataIndex: GroupDetailTableFields.guestName,
  //       render: (props: CodeItemProps) => (
  //         <InputItem
  //           {...props}
  //           focusId={focusedId}
  //           setEmail={handleSetName}
  //           emailstate={guestState}
  //           setFocusId={setFocusedId}
  //           field={'name'}
  //           editingId={editingId}
  //           eCodeState={eCodeState}
  //           eCodeSetState={handleEdit}
  //         />
  //       ),
  //     },
  //     {
  //       key: GroupDetailTableFields.email,
  //       title: 'Email',
  //       dataIndex: GroupDetailTableFields.email,
  //       render: (props: CodeItemProps) => (
  //         <InputItem
  //           {...props}
  //           focusId={focusedId}
  //           setEmail={handleSetEmail}
  //           emailstate={guestState}
  //           setFocusId={setFocusedId}
  //           field={'email'}
  //           editingId={editingId}
  //           eCodeState={eCodeState}
  //           eCodeSetState={handleEdit}
  //         />
  //       ),
  //     },
  //     {
  //       key: GroupDetailTableFields.quantity,
  //       title: GroupDetailTableColumnsTitles.quantity,
  //       dataIndex: GroupDetailTableFields.code,
  //       render: (props: CodeItemProps) => <QuantityItem {...props} editingId={editingId} codesState={codesState} />,
  //     },
  //     {
  //       key: GroupDetailTableFields.code,
  //       title: GroupDetailTableColumnsTitles.code,
  //       dataIndex: GroupDetailTableFields.code,
  //       render: (props: CodeItemProps) => <CodesItem {...props} editingId={editingId} codesState={codesState} />,
  //     },
  //     {
  //       key: GroupDetailTableFields.actions,
  //       title: (
  //         <>
  //           {/*{GroupDetailTableColumnsTitles.actions}*/}
  //           <Search
  //             searchPromoCodes={searchPromoCodes}
  //             showResult={setIsSearchRes}
  //             value={searchValue}
  //             setValue={setSearchValue}
  //           />
  //         </>
  //       ),
  //       dataIndex: GroupDetailTableFields.actions,
  //       render: (props: ActionsItemProps) => (
  //         <ActionsItem
  //           {...props}
  //           focusedId={focusedId}
  //           onSendCode={handleSend}
  //           setFocusId={setFocusedId}
  //           loading={loading}
  //           emailstate={guestState}
  //           eCodeState={eCodeState}
  //           editingId={editingId}
  //           onUpdateCode={onSave}
  //           setEditingId={setEditing}
  //           updateTarget={updateTargets}
  //           codeCount={props?.id ? codesState.codes?.[props.id] : 0}
  //           setDelPopup={setDelPopup}
  //         />
  //       ),
  //     },
  //   ];
  return [];
};

export const Search: React.FC<SearchProps> = ({value, setValue, searchPromoCodes, showResult}) => {
  const [open, setOpen] = useState(false);
  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
    setTimeout(() => document?.getElementById('search-input')?.focus(), 100);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (!e.target.value) searchPromoCodes('');
  };
  const handleOnSearch = () => {
    searchPromoCodes(value);
    setOpen(false);
    showResult(true);
  };

  const searchInput = (
    <SearchWrapper>
      <SearchInput
        prefix={<SearchOutlined />}
        placeholder="Search by email or name..."
        onChange={handleInputChange}
        onPressEnter={handleOnSearch}
        value={value}
        id="search-input"
      />
      <EnterButton onClick={handleOnSearch} disabled={value === ''}>
        Search
      </EnterButton>
    </SearchWrapper>
  );

  return (
    <SearchBtn>
      <Dropdown
        overlay={searchInput}
        trigger={['click']}
        placement="bottomRight"
        onVisibleChange={handleOpenChange}
        visible={open}>
        {open ? <CloseOutlined /> : <SearchOutlined />}
      </Dropdown>
    </SearchBtn>
  );
};
