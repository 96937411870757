import React from 'react';
import {DashboardPage} from '../../components/Dashboard';
import {useDeleteCodesGroup, useGetCodesTable} from '../../hooks/codes';
import {useNavigate} from 'react-router-dom';
import {route} from '../../constants/routes';

export const Dashboard = () => {
  const {
    codes,
    metrics,
    refetch,
    loading,
    searchCodes,
    pagination,
    metricsLoading,
    isSearching,
    currentTab,
    changeTab,
  } = useGetCodesTable();
  const navigate = useNavigate();
  const canAddEdit = true;
  const goEdit = (id: string) => canAddEdit && navigate(route.editCodeGroup.get({id}));
  const {onDelete} = useDeleteCodesGroup(refetch);

  return (
    <DashboardPage
      codes={codes}
      goEdit={goEdit}
      onDelete={onDelete}
      loading={loading}
      metrics={metrics}
      canAddEdit={canAddEdit}
      searchCodes={searchCodes}
      pagination={pagination}
      metricsLoading={metricsLoading}
      isSearching={isSearching}
      changeTab={changeTab}
      currentTab={currentTab}
    />
  );
};
