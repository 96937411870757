export const FormTips = {
  clientName:
    'Suggested client name format: Company name (Enchant location). I.e. Hilton Hotel (Washington, DC)\n' +
    '\n' +
    "The content you enter here will be visible on both the Client Portal and the Guests' end.",
  clientEmail: 'The Portal will send the Enchant Magic Link to this email address.',
  clientLogo: 'To use on emails. White colour logo PNG with transparent background. 600px max.',
  ticketType: 'Please choose from one of the following options.',
  description:
    "Describe the conditions and restrictions of the codes. The content you enter here will be visible on both the Client Portal and the Guests' end.\n" +
    '\n' +
    'Example description:\n' +
    '- Complimentary 1 GA Ticket\n' +
    '- Applicable on any GA Ticket Types (Adult, Child etc.)\n' +
    '- Applicable on All Event Dates\n' +
    '- Applicable in St. Petersburg, FL only',
};
