import React, {useEffect, useState} from 'react';
import {PageWrapper, TitleLine} from '../styles';
import {LinkBack} from '../../../ui-kit/Button/LinkBack';
import {route} from '../../../constants/routes';
import {GroupDetailTable, ModalLvl} from './Table';
import {DashboardCardT} from '../../../types/dashboard';
import {getGroupLocName, getTableCodesFilterOptions} from '../../../helpers/codes';
import {CodeStatusTypes, GenerateCSVResT, GroupDetailTableDataT} from '../../../types/codes';
import {BigCard} from '../../../ui-kit/StaticCard';
import {TableCards} from './TableCards';
import {SuccessAddCodesIcon, ValueItem, ValueNameItem, ValueWrapper} from './styles';
import {CodeGroupT, SellerT} from '../../../queries/types/codes';
import {StyledTableContentWrapper} from '../../../ui-kit/Table/styles';
import {TableViews, usePromoCodesActionsT} from '../../../hooks/codes';
import {Modal} from 'antd';
import {UploadModalEmails, UploadModal} from './UploadModal';
import {updateTargetT, useSetEmailsT} from '../../../hooks/emails';
import {CodeAccessTypes} from '../../../ui-kit/helpers/codes';
import {ModalDescr} from '../../Dashboard/styles';
import {User} from '../../../types/auth';
import {getFilterTableData} from '../../../helpers/helpers';
import {ResetModal} from './ResetModal';
// import {useUploadEmailsT} from '../../../hooks/emails';

type ViewCodeGroupProps = {
  code?: CodeGroupT;
  codeLoading?: boolean;
  tableData?: GroupDetailTableDataT[];
  onUpdate?: usePromoCodesActionsT['onUpdate'];
  onDelete?: usePromoCodesActionsT['onDelete'];
  addCodes?: (value: any[]) => Promise<boolean | undefined>;
  onDeletePromoCode?: usePromoCodesActionsT['onDelete'];
  onSendCode: (id?: string) => Promise<boolean>;
  onSendGuest: (ids?: string[]) => Promise<boolean>;
  listLoading?: boolean;
  onUploadEmails: (file: any, onSuccess?: () => void) => Promise<any>;
  // uploadEmails: useUploadEmailsT['upload'];
  id: string | undefined;
  fullRefetch: () => void;
  emailsData: useSetEmailsT;
  canAddEdit?: boolean;
  sellers?: SellerT[];
  user?: User | null;
  updateTarget: updateTargetT;
  searchPromoCodes: (v: string) => void;
  setTableView: (view: TableViews) => void;
  tableView: TableViews;
  modals: {
    helpModal: {
      expanded: boolean | undefined;
    };
    subsequent: boolean | undefined;
    triggerFlow: () => void;
    restoreSubsequent: () => void;
    uploadTriggered: boolean | undefined;
    isOnboarding: boolean;
    restoreOnboarding: () => void;
    triggerOnboarding?: string;
  };
  downloadCSV?: () => GenerateCSVResT;
  handleReset?: () => Promise<any>;
};

export const ViewCodeGroup: React.FC<ViewCodeGroupProps> = ({
  code,
  codeLoading,
  tableData,
  onUpdate,
  onDelete,
  onSendCode,
  addCodes,
  listLoading,
  // uploadEmails,
  emailsData,
  fullRefetch,
  sellers,
  user,
  updateTarget,
  searchPromoCodes,
  tableView,
  setTableView,
  onDeletePromoCode,
  onSendGuest,
  modals,
  onUploadEmails,
  downloadCSV,
  handleReset,
}) => {
  const {triggerFlow, subsequent, isOnboarding, uploadTriggered} = modals;
  const [filter, setFilter] = useState<CodeStatusTypes>(CodeStatusTypes.all);
  const [modalLvl, setModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [emailsModalLvl, setEmailsModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const handleSetFilter = (value: CodeStatusTypes) => setFilter(value);
  const options = getTableCodesFilterOptions();
  const [filterTableData, setFilterTableData] = useState<GroupDetailTableDataT[] | undefined>();
  const {openResetModal} = ResetModal(handleReset);
  const cards: DashboardCardT[] = [
    {
      label: 'Total Codes',
      value: String(code?.codesUploaded ?? ''),
    },
    {
      label: 'Codes Sent',
      value: String(code?.codesSent ?? ''),
      tooltip: 'The total numbers of codes that clients send guests.',
    },
    // {
    //   label: 'Codes Applied',
    //   value: String(code?.codesUsed ?? ''),
    //   tooltip: 'The total numbers of guests who open their code emails.',
    // },
  ];
  const cardLineValues = {
    contact: [code?.contactPerson, code?.clientEmailList?.join('\n') || code?.clientEmail],
    ticketTypes: code?.ticketType?.split(','),
  };

  const cardLines = [
    {
      label: 'Contact Details',
      value: (
        <ValueWrapper>
          <ValueNameItem>{cardLineValues.contact[0]}</ValueNameItem>
          <ValueItem>{cardLineValues.contact[1]}</ValueItem>
        </ValueWrapper>
      ),
    },
    {
      label: 'Ticket Types',
      value: (
        <ValueWrapper>
          {cardLineValues?.ticketTypes?.map((el) => (
            <ValueItem key={el}>{el}</ValueItem>
          ))}
        </ValueWrapper>
      ),
    },
    {
      label: 'Details',
      value: (
        <ValueWrapper>
          <ValueItem>All tickets are applicable in {getGroupLocName(sellers, code)} only</ValueItem>
          <ValueItem>{'-'}</ValueItem>
          <ValueItem>{code?.description}</ValueItem>
          {/*<ValueItem>Applicable on any ticket types and any event dates.</ValueItem>*/}
          {/*<ValueItem>Application in Washington, DC only</ValueItem>*/}
        </ValueWrapper>
      ),
    },
  ];
  const openUploadEmails = () => {
    //always show template modal
    if (subsequent || true) {
      triggerFlow();
      // restoreSubsequent();
      return;
    }
    //setModalLvl(ModalLvl.success);
  };

  const openUpload = () => setModalLvl(ModalLvl.success);

  const onCancelUpload = () => setModalLvl(ModalLvl.closed);

  const onCancelUploadEmails = () => setEmailsModalLvl(ModalLvl.closed);

  const onAddPromoCodes = async (value: any[]) => {
    const res = await addCodes?.(value);
    if (res) setModalLvl(ModalLvl.confirm);
    Modal.destroyAll();
  };

  useEffect(() => {
    if (modalLvl === ModalLvl.success) {
      Modal.success({
        onOk: () => {
          setModalLvl(ModalLvl.confirm);
        },
        onCancel: () => setModalLvl(ModalLvl.closed),
        modalRender: () => (
          <UploadModal onCancel={onCancelUpload} onAdd={onAddPromoCodes} titleName={code?.clientName} />
        ),
        style: {pointerEvents: 'all'},
      });
    }
    if (modalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: `Additional Promo Codes Added!`,
        content: <ModalDescr>The new codes are added to the list.</ModalDescr>,
        okText: 'Done',
        onOk: () => {
          setModalLvl(ModalLvl.closed);
        },
        onCancel: () => setModalLvl(ModalLvl.closed),
        style: {pointerEvents: 'all'},
        icon: <SuccessAddCodesIcon />,
      });
    }
    if (modalLvl === ModalLvl.closed) Modal.destroyAll();
  }, [modalLvl]);

  const onUploadSuccess = () => {
    setEmailsModalLvl(ModalLvl.closed);
  };

  const onAddEmailsCodes = async (value: any[]) => await onUploadEmails?.(value);

  useEffect(() => {
    if (emailsModalLvl === ModalLvl.success) {
      Modal.success({
        onOk: () => {
          setEmailsModalLvl(ModalLvl.closed);
        },
        modalRender: () => (
          <UploadModalEmails
            onCancel={onCancelUploadEmails}
            onAdd={onAddEmailsCodes}
            uploadTriggered={uploadTriggered}
            onUploadSuccess={onUploadSuccess}
            isOnboarding={isOnboarding}
          />
        ),
        width: 460,
        maskClosable: true,
        afterClose: () => {
          setEmailsModalLvl(ModalLvl.closed);
        },
        style: {pointerEvents: 'all'},
      });
    }
    if (emailsModalLvl === ModalLvl.closed) Modal.destroyAll();
  }, [emailsModalLvl]);

  useEffect(() => {
    if (uploadTriggered) setEmailsModalLvl(ModalLvl.success);
  }, [uploadTriggered]);

  useEffect(() => {
    const filterTableData = getFilterTableData(filter, tableData);
    setFilterTableData(filterTableData);
  }, [tableData, filter]);

  return (
    <PageWrapper>
      <TitleLine>
        <LinkBack to={route.dashboard.path}>Back to Dashboard</LinkBack>
        {/*<StatusPart status={CodeAccessTypes.sent} />*/}
      </TitleLine>
      <BigCard
        cardTitle={code?.clientName}
        lines={cardLines}
        buttonText={'Edit'}
        status={code?.outboundStatus as CodeAccessTypes}
        buttonLink={route.editCodeGroup.get({id: String(code?.id)}) + '?fromDetailed=true'}
        additionalInfo={[
          'Created By:',
          `${code?.user?.firstName} ${code?.user?.lastName}, ${code?.department} (${code?.groupType})`,
        ]}
        loading={codeLoading}
        logo={code?.clientLogoUrl}
      />
      <StyledTableContentWrapper>
        <TableCards
          cards={cards}
          createdAt={code?.createdAt}
          updatedAt={code?.updatedAt}
          filterValue={filter}
          setFilter={handleSetFilter}
          filterOptions={options}
          onAddCodes={openUpload}
          onAddEmails={openUploadEmails}
          user={user}
          downloadCSV={downloadCSV}
          openResetModal={openResetModal}
        />
        <GroupDetailTable
          promoCodes={filterTableData}
          onDelete={onDelete}
          onUpdate={onUpdate}
          listLoading={listLoading}
          onSendCode={onSendCode}
          onSendGuest={onSendGuest}
          emailsData={emailsData}
          updateTarget={updateTarget}
          searchPromoCodes={searchPromoCodes}
          fullRefetch={fullRefetch}
          setTableView={setTableView}
          tableView={tableView}
          onDeletePromoCode={onDeletePromoCode}
          filter={filter}
          setFilter={setFilter}
          totalCodes={code?.codesUploaded}
          usedCodes={code?.codesAssigned}
          groupId={code?.id}
        />
      </StyledTableContentWrapper>
    </PageWrapper>
  );
};
